<template>
    <div class="stage-inner-container-padded">
        <div v-show="warnUnsaved" class="p-2 fs-1-5 bg-warning">
            <span class="font-weight-bold mr-2">{{unsavedChanges.length}} Unsaved Changes</span>
            <b-button
                variant="success"
                @click="saveAllChanges"
            >
                Save Changes
            </b-button>
        </div>
        <b-table
            small
            striped 
            hover 
            stacked="lg"
            :items="mappings" 
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :busy="waiting"
        >
            <template #table-busy>
               <wait-dots :title="waitTitle"></wait-dots>
            </template>
            <template #cell(site_type_name)="data">
                <span class="font-weight-bold">
                    {{data.item.siteType.value}}
                </span>
            </template>
            <template #cell()="data">
                <div class="d-flex w-100 flex-row align-items-center">
                    <div class="flex-shrink-1">
                        <span class=" pr-1" v-if="isChanged(data)">
                            <b-icon-circle-fill variant="info"></b-icon-circle-fill>
                        </span>
                        <span class="pr-1">
                            Every: 
                        </span>
                    </div>
                    <div class="flex-shrink-1">
                        <b-form-input
                            type="number" 
                            v-model="data.value.count" 
                            :options="allowedIntervalLengthOptions"
                            :min="1"
                            :max="1825"
                            :step="1"
                            :number="true"
                            size="sm"
                            @change="markUpdated(data)"
                        >
                        </b-form-input>
                    </div>
                    <div class="flex-grow-1">
                        <b-form-select 
                            v-model="data.value.type" 
                            :options="allowedIntervalLengthOptions"
                            @change="markUpdated(data)"
                            size="sm"
                        >
                        </b-form-select>
                    </div>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = butils.underscore;
var async = require("async");

import waitDots from '@/components/subcomponents/WaitingDots.vue'

export default {
    name: 'SiteTypeIntervalSetup',
    components:{
        'wait-dots': waitDots
    },
    props:{
    },
    data(){
        return{
            waiting: false,
            waitTitle: "Loading",
            siteTypes: [],
            rawMapping: [],
            mappings: [],
            fields: [
                {
                    key: 'site_type_name',
                    label: 'Site Type',
                    sortable: true
                },
                {
                    key: 'bfpa_test',
                    label: 'BFPA Test',
                    sortable: false
                },
                {
                    key: 'ag_inspection',
                    label: 'AG Inspection',
                    sortable: false
                },
                {
                    key: 'dual_check_maintenance',
                    label: 'Dual Check Maint.',
                    sortable: false
                },
            ],
            allowedIntervalLengthOptions:[
                {value: 'years', text: "Year(s)"},
                {value: 'months', text: "Months"},
                {value: 'days', text: "Days"},
            ],
            warnUnsaved: false,
            unsavedChanges: [],
            sortBy: null,
            sortDesc: false,
        }
    },
    methods:{
        saveAllChanges(){
            this.waiting = true;
            this.waitTitle = "Saving... Please Stand By.";
            var list = [];
            this.unsavedChanges.forEach((chg)=>{
                var update = _.find(this.mappings, (map)=>{ return map.raw.site_type_id === chg.siteTypeID; })
                if(update != undefined){
                    var tmp = {
                        site_type_id: chg.siteTypeID,
                    }
                    var actionTypes = ['ag_inspection', 'bfpa_test', 'dual_check_maintenance'];
                    actionTypes.forEach((act)=>{
                        tmp[act] = {
                            type: update[act].type,
                            count: update[act].count
                        };
                    })
                    list.push(tmp)
                }else{
                    console.log(`Expected To Find Map With Site Type ID: ${chg.hazID}`);
                }
            })
            this.startProcessingUpdates(list);
        },
        startProcessingUpdates(list){
            async.eachSeries(list, 
                (item, callback)=>{
                    butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/config/compliance/site_type_interval`, item)
                    .then((response)=>{
                        callback();
                    })
                    .catch((err)=>{
                        if(butils.isError401(err)){
                            butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                        }else if(butils.isError403(err)){
                            butils.createToast(this, 'Permissions To Update Site Type Interval Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                        }else{
                            console.log(err);
                            butils.createToast(this, 'An Error Occured While Updating Site Type Interval', 'Try again, if the problem persists, contact support', 'danger');
                        }
                        callback();
                    })
                }
            )
            .then((ignore)=>{
                this.waiting = false;
                this.unsavedChanges = [];
                this.warnUnsaved = false;
                this.performRequestsOrdered();
            })
            .catch((err)=>{
                this.waiting = false;
                console.log(err);
            })
        },
        isChanged(data){
            var found = _.find(this.unsavedChanges, (uns)=>{ return uns.siteTypeID === data.item.raw.site_type_id; })
            if(found != undefined){
                var field = _.find(found.changed, (fld)=>{return fld.key === data.field.key; })
                if(field != undefined){
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        },
        removeUnnescicaryChangesAndWarn(){
            this.mappings.forEach((map)=>{
                // Find changes associated with this hazard type map
                var found = _.find(this.unsavedChanges, (uns)=>{ return uns.siteTypeID === map.raw.site_type_id; })
                var originalValue = _.find(this.rawMapping, (rm)=>{ return rm.site_type_id === map.raw.site_type_id; })
                if(found != undefined && originalValue != undefined){
                    found.changed = _.filter(found.changed, (chg)=>{ 
                        var keys = _.keys(originalValue[chg.key])
                        var origType = keys[0];
                        var origCount = originalValue[chg.key][origType];
                        if(chg.value.count == origCount && chg.value.type == origType){
                            // This can be removed, its the same as the original
                            return false;
                        }else{
                            return true;
                        }
                    })
                    // Remove any unsaved changes entries with NO changes in the change array
                    this.unsavedChanges = _.filter(this.unsavedChanges, (uns)=>{ return uns.changed.length > 0; })
                    if(this.unsavedChanges.length == 0){
                        this.warnUnsaved = false;
                    }else{
                        this.warnUnsaved = true;
                    }
                }else{
                    // No Changes Associated
                }
            })
        },
        markUpdated(data){
            var found = _.find(this.unsavedChanges, (uns)=>{ return uns.siteTypeID === data.item.raw.site_type_id; })
            if(found != undefined){
                var field = _.find(found.changed, (chg)=>{ return chg.key == data.field.key; })
                if(field != undefined){
                    field.value = data.value;
                    
                }else{
                    found.changed.push({
                        key: data.field.key,
                        label: data.field.label,
                        value: data.value
                    })
                }
            }else{
                this.unsavedChanges.push({
                    siteTypeID: data.item.raw.site_type_id,
                    siteTypeName: data.item.siteType.value,
                    changed: [{
                        key: data.field.key,
                        label: data.field.label,
                        value: data.value
                    }]
                })
            }
            this.removeUnnescicaryChangesAndWarn();
        },
        fetchSiteTypes(){
            return new Promise((resolve, reject)=>{
                butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/choices/site_types`)
                .then((response)=>{
                    this.siteTypes = response.data.result.records;
                    resolve();
                })
                .catch((err)=>{
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Permissions To Fetch Site Types Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                    }else{
                        console.log(err);
                        butils.createToast(this, 'An Error Occured While Fetching Site Types', 'Try again, if the problem persists, contact support', 'danger');
                    }
                    reject();
                })
            })
        },
        fetchSiteTypeIntervalMapping(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/config/compliance/site_type_interval`)
            .then((response)=>{
                var siteType = response.data.result;
                this.rawMapping = siteType;
                // Build a map object for every hazard type, even if there is no current map for that hazard type
                this.mappings = _.map(this.siteTypes, (st)=>{
                    var tmp = {};
                    tmp._cellVariants = {};
                    tmp.siteType = st;
                    tmp.site_type_name = st.value;
                    var matchedMap = _.find(siteType, (stMap)=>{ return stMap.site_type_id == st.id; })
                    if(matchedMap != undefined){
                        tmp.raw = matchedMap;
                        var actionTypes = ['ag_inspection', 'bfpa_test', 'dual_check_maintenance'];
                        actionTypes.forEach((act)=>{
                            var keys = _.keys(matchedMap[act])
                            var intervalType = keys[0];
                            var intervalCount = matchedMap[act][intervalType];
                            tmp[act] = {
                                type: intervalType,
                                count: intervalCount
                            };
                        })
                    }else{
                        tmp.raw = null;
                        console.log(`No Site Type Interval Mapping Found For Site Type ID: ${st.id} - Value: ${st.value}`)
                    }
                    return tmp;
                })
                // Not waiting anymore
                this.waiting = false;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permissions To Fetch Site Type Interval Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Fetching Site Type Intervals', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        performRequestsOrdered(){
            this.waiting = true;
            var startup = [this.fetchSiteTypes()]

            Promise.all(startup)
            .then((ignore)=>{
                this.fetchSiteTypeIntervalMapping();
            })
            .catch(()=>{
                this.waiting = false;
            })
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){
        this.performRequestsOrdered();
    },
    mounted(){
        
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
</style>